// import { useState, useEffect } from "react";
import {  Accordion } from "react-bootstrap";
// import { ArrowRightCircle } from 'react-bootstrap-icons';
import purpleBall from "../assets/img/purpleball.png";
import { Container, Row } from "react-bootstrap";
import 'animate.css';


export const FAQ = () => {


  return (
    <section className="faq" id="faq" style={{textAlign:"center",position:"relative"}}>
      <div  style={{zIndex:0, opacity:"100%", position:"absolute", right:"35%", top:"0%", width:"70px",height:"70px"}}><img src={purpleBall} style={{width:"100%"}} alt="decoration"/></div>
  
        <div>
          <div style={{fontSize:"24px",lineHeight:"24px",textAlign:"center",fontWeight:"500",textTransform:"uppercase"}}>Still Unsure?</div>
          <div  className="blueFadeText" style={{fontSize:"64px",lineHeight:"64px",textAlign:"center",fontWeight:"700", textTransform:"uppercase"}}>FAQs</div>               
        </div>

              <Container>
      
                    <Row style={{textAlign:"center",padding:"24px 0"}}>
           <Accordion  style={{textAlign:"left"}} >
            <Accordion.Item eventKey="0">
              <Accordion.Header className="acc-header"><h4>How are you different?</h4></Accordion.Header>
              <Accordion.Body  className="acc-body">
              While we are comprehensive and strive to grow your channels with metrics like views, subscribers, and engagement, we also know you can’t deposit these into your bank account. So, we focus on driving sales and impact by building you an online brand and cultivating a loyal, trusting audience.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="acc-header"><h4>When will I see results?</h4></Accordion.Header>
              <Accordion.Body className="acc-body">
              Admittedly, while we do everything we possibly can to help, results are never guaranteed. Some of our clients have seen revenue double within 2 months. Others have gone a few months without seeing substantial revenue returns. Many give up before they have a chance to sell. Building a brand online is an arduous process that takes an entire team to make happen, but the returns are exponential.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="acc-header"><h4>How much does this cost?</h4></Accordion.Header>
              <Accordion.Body className="acc-body">
              Currently, our offers range all the way from $500/mo to $7,000/mo. We can provide you with an accurate price quote after we are able to hop on call and better understand your business and what services will be necessary to grow your brand.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="acc-header"><h4>What do I have to do?</h4></Accordion.Header>
              <Accordion.Body className="acc-body">
              Only about 2-3 hours of work per week. All you HAVE to do is film yourself and send us the files. This will be discussed over a strategy call.              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          </Row>
          </Container>
    </section>

  )
}
