import './App.css';
import React from 'react';
import { HashRouter as Router, Routes, Route} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import {Home} from './components/Home';
import {Blog} from './components/Blog';

function App(props) {

  return (
  <Router>
    <Routes>
      <Route path="/blog/" element={<Blog {...props}/>}></Route>
      <Route path="/blog/:bid" element={<Blog {...props}/>}></Route>
      <Route path="/" element={<Home {...props}/>}></Route>
      <Route path="/*" element={<Home {...props}/>}></Route>
      <Route path="*" element={<Home {...props}/>}></Route>
    </Routes>
  </Router>

  );
}

export default App;
