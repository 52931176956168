// import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import headerImg from "../assets/img/header-img.svg";
import BannerImg from "../assets/img/v3BannerImg.svg";


export const Banner = () => {
  
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center" xs={1} md={1} lg={2}>
          <Col>
              <div >
                {/* <p style={{fontSize:"24px",fontWeight:"300",lineHeight:"24px"}}>Welcome to</p>
                <p style={{fontWeight:"700",fontSize:"48px",marginBottom:"20px"}}>Vontent</p> */}
                <p style={{textTransform:"uppercase",fontWeight:"700",fontSize:"42px",lineHeight:"42px"}}>Organically</p>
                <p className="greenFadeText" style={{textTransform:"uppercase",fontWeight:"700",fontSize:"42px",lineHeight:"42px"}}>build and scale</p>
                <p style={{textTransform:"uppercase",fontWeight:"700",fontSize:"42px",lineHeight:"42px"}}>a profitable</p>
                <p className="blueFadeText" style={{textTransform:"uppercase",fontWeight:"700",fontSize:"42px",lineHeight:"42px"}}>Digital Brand</p>
                <p style={{textTransform:"uppercase",fontSize:"12px",paddingTop:"20px"}}>Looking to sell more of your products, services, or skills online? We work with you to build a digital brand that lasts.</p>
              </div>

          </Col>
          <Col>
          <div style={{position:"relative",minHeight:"300px"}}>
            <div style={{position:"absolute",margin:"0 auto",zIndex:0}}><img src={BannerImg} width="100%" alt="Header Img"/></div>
          </div>
          </Col>       
        </Row>
      </Container>
    </section>

  )
}
