import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import emailIcon from "../assets/img/email.svg";
import { HashLink } from 'react-router-hash-link';
// import {
//   BrowserRouter as Router
// } from "react-router-dom";

export const NavBar = () => {

  // const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  // const onUpdateActiveLink = (value) => {
  //   setActiveLink(value);
  // }

  // const jumpToCalender = () =>{
  //   window.location.href="/#calendly";
  // }
  return (
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
        <Navbar.Brand href="#">
          <div style={{display: "flex",alignItems: "center"}}>
          <HashLink to='#home'>
          <div style={{display:"inline-block",textTransform:"uppercase",color:"#fff",fontWeight:"800",fontSize:"24px"}}>Vontent</div><div style={{display:"inline-block",textTransform:"uppercase",color:"#fff",fontWeight:"300",fontSize:"24px"}}>.co</div>
          </HashLink>
          </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {/* 
              <Nav.Link href="#home" className={'navbar-link'} >Home</Nav.Link>
              <Nav.Link href="#skills" className={'navbar-link'} >Features</Nav.Link>
              <Nav.Link href="#projects" className={'navbar-link'} >About us</Nav.Link>
              <Nav.Link href="#projects" className={'navbar-link'} >Contact</Nav.Link>
            */}
            <span className="navbar-text">
              <div className="social-icon">
                <a href="mailto:gavin@vontent.co"><img src={emailIcon} alt="Email me" /></a>

              </div>
              <HashLink to='#calendly'>
                <button className="vvd" ><span style={{textTransform:"uppercase"}}>Book a free call</span></button>
              </HashLink>
            </span>
            </Nav> 
          </Navbar.Collapse>
        </Container>
      </Navbar>
  )
}
