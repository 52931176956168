// import logo from './logo.svg';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./NavBar";
import { Banner } from "./Banner";
import { Footer } from "./Footer";
import { How } from "./How";
import 'animate.css';

import { FAQ } from './FAQ';
import { Calendly } from './Calendly';
import WhatWeWorkedOn from './WhatWeWorkedOn';
import {Speak} from './Speak';
import {Trust} from './Trust';
export const Home = () => {
  return (
    <div className="App" id="home" >
        <div style={{position:"absolute", height:"100%",width:"100%",overflow:"hidden"}}>
        <div className="radialGradient" style={{zIndex:0, opacity:"50%", position:"absolute", left:"-350px", top:"5%", width:"700px",height:"700px"}}/>
        <div className="radialGradient" style={{zIndex:0, position:"absolute",right:"-350px", top:"55%", width:"700px",height:"700px"}}/>
        <div className="radialGradient" style={{zIndex:0, opacity:"50%", position:"absolute", left:"50%", top:"43%", width:"400px",height:"400px"}}/>
        <div className="radialGradient" style={{zIndex:0, opacity:"50%", position:"absolute", right:"-200px", top:"3%", width:"400px",height:"400px"}}/>
        <div className="radialGradient" style={{zIndex:0, opacity:"50%", position:"absolute", left:"50%", top:"73%", width:"400px",height:"400px"}}/>
        <div className="radialGradient" style={{zIndex:0, opacity:"50%", position:"absolute", left:"-350px", top:"88%", width:"700px",height:"700px"}}/>
        </div>
      <NavBar />
      <Banner />
      {/* <DesignElement /> */}
      <Speak/>
      <How/>
      {/* <NeedYoutube/> */}
      <Trust style={{zIndex:5}}/>

      <WhatWeWorkedOn />
      <FAQ/>
      <Calendly/>
      <Footer />
    </div>
  );
}

