import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logoMark.svg";
import navIcon1 from '../assets/img/nav-icon1.svg';
// import navIcon2 from '../assets/img/nav-icon2.svg';
// import navIcon3 from '../assets/img/nav-icon3.svg';
// import navIcon4 from '../assets/img/nav-icon4.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col className="text-center">
          <p  className="greenFadeText" style={{fontWeight:"600"}}>Contact us!</p>
          <div  style={{height:"32px",fontWeight:"400",color:"#fff"}}>gavin@vontent.co<br/>425 394 3733</div>
          </Col>
          <Col className="text-center">
          <img style={{height:"36px", width:"40px"}} src={logo} alt="Logo" />&nbsp;&nbsp;<div style={{display:"inline-block",textTransform:"uppercase",color:"#fff",fontWeight:"300",fontSize:"24px", padding:"0 10px 0 0"}}>|</div><div style={{display:"inline-block",textTransform:"uppercase",color:"#fff",fontWeight:"800",fontSize:"24px"}}>Vontent</div><div style={{display:"inline-block",textTransform:"uppercase",color:"#fff",fontWeight:"300",fontSize:"24px"}}>.co</div>
            <p className="blueFadeText" style={{fontWeight:"600",paddingTop:"8px"}}>Grow a trusting and loyal audience through the power of video content and make more $</p>
            <p style={{color:"#fff"}}>Copyright 2023.  VONTENT LLC. All Rights Reserved.</p>
          </Col>
          <Col className="text-center">
            <p  className="greenFadeText" style={{fontWeight:"600"}}>Social</p>
            <div style={{display:"flex",margin:"0 40px",textAlign:"center",justifyContent:"space-around"}}>
              {/* <a href="https://www.instagram.com/" rel="noreferrer"  target="_blank"><img style={{height:"32px"}} src={navIcon3} alt="instagram"/></a>
              <a href="https://www.facebook.com/"  rel="noreferrer" target="_blank"><img style={{height:"32px"}}  src={navIcon2} alt="facebook"/></a>
              <a href="https://www.twitter.com/"  rel="noreferrer" target="_blank"><img style={{height:"32px"}}  src={navIcon4} alt="twitter"/></a> */}
              <a href="https://www.linkedin.com/company/vontent/about/"  rel="noreferrer" target="_blank"><img style={{height:"32px"}}  src={navIcon1} alt="linkedIn"/></a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
