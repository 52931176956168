// import { useState, useEffect } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
// import TrackVisibility from 'react-on-screen';
import purpleBall from "../assets/img/purpleball.png";

import { InlineWidget } from "react-calendly";

export const Calendly = () => {


  return (
    <section className="calendly" id="calendly" style={{position:"relative"}}>
            <div  style={{zIndex:0, opacity:"100%", position:"absolute", left:"15%", top:"10%", width:"70px",height:"70px"}}><img src={purpleBall} style={{width:"100%"}} alt="decoration"/></div>

        <div>
          <div style={{fontSize:"24px",lineHeight:"24px",textAlign:"center",fontWeight:"500",textTransform:"uppercase"}}>Want to see if you're a fit?</div>
          <div  className="blueFadeText" style={{fontSize:"64px",lineHeight:"64px",textAlign:"center",fontWeight:"700", textTransform:"uppercase"}}>Book a free call!</div>               
        </div>

              <InlineWidget style={{overflow:"hidden"}} url="https://calendly.com/gavinherman-connect/quick-chat-with-gavin" />
    </section>

  )
}
