import Trust_Baxate from "../assets/img/Trust_Baxate.png";
import Trust_JeremyAwakens from "../assets/img/Trust_JeremyAwakens.png";
import Trust_DMAC from "../assets/img/Trust_DMAC.png";
import Trust_DCMMarketing from "../assets/img/Trust_DCMMarketing.png";
import Trust_PassionateFew from "../assets/img/Trust_ThePassionateFew.png";
// import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import purpleBall from "../assets/img/purpleball.png";

// import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';

export const How = () => {


  return (
    <section className="how" id="how" style={{position:"relative"}}>
      <div  style={{zIndex:0, opacity:"80%", position:"absolute", left:"55%", top:"22%", width:"40px",height:"40px"}}><img src={purpleBall} style={{width:"100%"}} alt="decoration"/></div>
      <div  style={{zIndex:0, opacity:"80%", position:"absolute", left: "10%", bottom:"10%", width:"50px",height:"50px"}}><img src={purpleBall} style={{width:"100%"}} alt="decoration"/></div>      
      <div  style={{zIndex:0, opacity:"80%", position:"absolute", right:"10%", top:"50%", width:"60px",height:"60px"}}><img src={purpleBall} style={{width:"100%"}} alt="decoration"/></div>

        <div>
          <div style={{fontSize:"24px",lineHeight:"24px",textAlign:"center",fontWeight:"500",textTransform:"uppercase"}}>How We</div>
          <div className="blueFadeText" style={{fontSize:"64px",lineHeight:"64px",textAlign:"center",fontWeight:"700", textTransform:"uppercase"}}>Help You</div>               
        </div>

        <Container style={{zIndex:15,position:"relative"}}>
          <Row style={{paddingTop:"20px"}}>
            <Col style={{paddingTop:"20px"}}>
                <div style={{minWidth:"320px",zIndex:5,position:"relative",display:"inline-block",padding:"20px 40px",borderRadius: "25px", background: "linear-gradient(90deg, #3AC1FF 0%, #000A0F 100%)"}}>
                    <div style={{textAlign:"left",backgroundColor:"transparent"}}><span style={{textTransform:"uppercase",textDecoration:"none",fontWeight:"600",textShadow:"2px 2px 2px #333"}}>Cohesive Digital Brand Creation</span></div>
              </div>

            </Col>
            <Col style={{minWidth:"320px",padding:"20px 40px",textAlign:"left"}}>
            If you don’t have brand guidelines in place, we create that for you. Things like logos, banners, fonts, colors, and more, all neatly wrapped into a style guide. If you already have these brand assets, we will translate those into a replicable and consistent video format on each social platform.
            </Col>
          
          </Row>

          <Row style={{paddingTop:"20px"}}>
            <Col style={{paddingTop:"20px"}}>
            <div style={{minWidth:"320px",zIndex:5,position:"relative",display:"inline-block",padding:"20px 40px",borderRadius: "25px", background: "linear-gradient(90deg, #3AC1FF 0%, #000A0F 100%)"}}>
                <div style={{textAlign:"left",backgroundColor:"transparent"}}><span style={{textTransform:"uppercase",textDecoration:"none",fontWeight:"600",textShadow:"2px 2px 2px #333"}}>Strategic Brand Positioning</span></div>
          </div>

            </Col>
            <Col style={{minWidth:"320px",padding:"20px 40px",textAlign:"left"}}>
            Once onboarded, we will speak about how we can position your social media channels to best sell your products, services, or skills. We will research your specific niche and develop different strategies to implement across your various social media platforms.
         
            </Col>
          
          </Row>

          <Row style={{paddingTop:"20px"}}>
            <Col style={{paddingTop:"20px"}}>
            <div style={{minWidth:"320px",zIndex:5,position:"relative",display:"inline-block",padding:"20px 40px",borderRadius: "25px", background: "linear-gradient(90deg, #3AC1FF 0%, #000A0F 100%)"}}>
                <div style={{textAlign:"left",backgroundColor:"transparent"}}><span style={{textTransform:"uppercase",textDecoration:"none",fontWeight:"600",textShadow:"2px 2px 2px #333"}}>Digital Brand Management</span></div>
          </div>

            </Col>
            <Col style={{minWidth:"320px",padding:"20px 40px",textAlign:"left"}}>
            Operate and control the brand identity, visibility, and credibility. Establish the company on socials, separate it from the competition, and foster a connection with your audience to create an opportunity to realize the company's potential.
        
            </Col>
          
          </Row>

          <Row style={{paddingTop:"20px"}}>
            <Col style={{paddingTop:"20px"}}>
            <div style={{minWidth:"320px",zIndex:5,display:"inline-block",padding:"20px 40px",borderRadius: "25px", background: "linear-gradient(90deg, #3AC1FF 0%, #000A0F 100%)"}}>
                <div style={{textAlign:"left",backgroundColor:"transparent"}}><span style={{textTransform:"uppercase",textDecoration:"none",fontWeight:"600",textShadow:"2px 2px 2px #333"}}>Video Content Creation</span></div>
          </div>

            </Col>
            <Col style={{minWidth:"320px",padding:"20px 40px",textAlign:"left"}}>
            Done for you video post-production and strategic uploading to YouTube, TikTok, Instagram. This includes video editing, subtitling, animating, thumbnails, and SEO with titles and descriptions.
          
            </Col>
          
          </Row>

          </Container>




    </section>

  )
}