import { Container, Row, Col,Button } from "react-bootstrap";
import { HashLink } from 'react-router-hash-link';
export const Speak = () => {


  return (
    <section className="speak" id="speak" style={{  padding:"0px 60px 0px 60px"}}>
          <Container>
          <div className="speakContainer">
        <Row className="aligh-items-center">
          <Col style={{minWidth:"220px"}}>
            <p style={{textTransform:"uppercase",fontWeight:"700",fontSize:"32px",lineHeight:"32px"}}>Are you a good fit?!</p>
            <p style={{fontSize:"14px"}}>Do you Have GREAT Products or Services (or Skills!) that you want to bring more eyes to and sell more of to exponentially scale your business?</p>
          
          </Col>
          <Col style={{minWidth:"220px",alignItems: "center", display: "flex", justifyContent: "center",  padding: "10px"}}>
          
          <div style={{padding:"20px 30px",borderRadius: "25px",border: "2px solid #3AC1FF",background: "linear-gradient(180deg, #FFF -32%, #3AC1FF 100%)"}}>
          <HashLink to='#calendly'>
                <Button style={{backgroundColor:"transparent"}}><span style={{textTransform:"uppercase",textDecoration:"none",fontWeight:"600"}}>I think I'm a fit!</span></Button>
              </HashLink>

          </div>
          </Col>
        </Row>
        </div>
      </Container>
    </section>

  )
}