import {Container, Row, Col, Tabs, Tab} from "react-bootstrap";
import 'animate.css';

function WhatWeWorkedOn(props) {

    return (
 <>
    <section className="projectList" id="projectList" style={{zIndex:15,position:"relative"}}>
    <div>
          <div  className="blueFadeText" style={{fontSize:"48px",lineHeight:"48px",textAlign:"center",fontWeight:"700", textTransform:"uppercase", paddingBottom:"20px"}}>Projects we've worked on</div>               
        </div>


          <Container > <Row>

            <Tabs defaultActiveKey="longForm" id="controlled-tab-example" variant="pills">
                <Tab eventKey="longForm" title="Long Form">
                    <Container>
                        <Row  xs={1} md={1} lg={3}
                           >
                            <Col style={{
                            padding: "10px 10px"
                        }}>
                                <div className="YouTubeContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://www.youtube.com/embed/i26Az3c34n0"
                                        title="YouTube video player"
                                        
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                            <Col style={{
                            padding: "10px 10px"
                        }}>
                                <div className="YouTubeContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://www.youtube.com/embed/rohsBbXHfEA"
                                        title="YouTube video player"
                                        
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                            <Col style={{
                            padding: "10px 10px"
                        }}>
                                <div className="YouTubeContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://www.youtube.com/embed/BQUPv6Q_Ua8"
                                        title="YouTube video player"
                                        
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                        </Row>
                        <Row xs={1} md={1} lg={3}>
                            <Col style={{
                            padding: "10px 10px"
                        }}>
                                <div className="YouTubeContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://www.youtube.com/embed/_MzcU7t1mWY"
                                        title="YouTube video player"
                                        
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                            <Col style={{
                            padding: "10px 10px"
                        }}>
                                <div className="YouTubeContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://www.youtube.com/embed/5aDf7ZBkae0"
                                        title="YouTube video player"
                                       
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                            <Col style={{
                            padding: "10px 10px"
                        }}>
                                <div className="YouTubeContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://www.youtube.com/embed/MD5-HByRxoA"
                                        title="YouTube video player"
                                        
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                        </Row>
                        <Row  xs={1} md={1} lg={3}
                            >
                            <Col style={{
                            padding: "10px 10px"
                        }}>
                                <div className="YouTubeContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://www.youtube.com/embed/Nd9rdmDtZ3A"
                                        title="YouTube video player"
                                       
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                            <Col style={{
                            padding: "10px 10px"
                        }}>
                                <div className="YouTubeContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://www.youtube.com/embed/_bWFduElur0"
                                        title="YouTube video player"
                                      
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                            <Col style={{
                            padding: "10px 10px"
                        }}>
                                <div className="YouTubeContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://www.youtube.com/embed/kntcUApUBoM"
                                        title="YouTube video player"
                                        
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </Tab>
                <Tab eventKey="shortForm" title="Short Form">
                    <Container>
                        <Row  xs={1} md={1} lg={3}
                            style={{padding:"10px 0"}}>
                            <Col>
                            <div className="YouTubeShortContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://youtube.com/embed/fVx8n7LltT0?feature=share"
                                        title="YouTube video player"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                            <Col>
                            <div className="YouTubeShortContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://youtube.com/embed/JAZrWDc-PmM?feature=share"
                                        title="YouTube video player"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div> 
                            </Col> 
                            <Col>
                            <div className="YouTubeShortContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://youtube.com/embed/YNT1akOS1ZM?feature=share"
                                        title="YouTube video player"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                        </Row>

                        <Row  xs={1} md={1} lg={3}
                            style={{padding:"10px 0"}}>
                            <Col>
                            <div className="YouTubeShortContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://youtube.com/embed/N1znE8eKy-c?feature=share"
                                        title="YouTube video player"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                            <Col>
                            <div className="YouTubeShortContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://youtube.com/embed/E52LjwcuAqc?feature=share"
                                        title="YouTube video player"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div> 
                            </Col> 
                            <Col>
                            <div className="YouTubeShortContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://youtube.com/embed/2NyRPgEPpoM?feature=share"
                                        title="YouTube video player"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                        </Row>
                        <Row  xs={1} md={1} lg={3}
                            style={{padding:"10px 0"}}>
                            <Col>
                            <div className="YouTubeShortContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://youtube.com/embed/BmnaBbyTHak?feature=share"
                                        title="YouTube video player"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                            <Col>
                            <div className="YouTubeShortContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://youtube.com/embed/Kv6d_CLZRm8?feature=share"
                                        title="YouTube video player"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div> 
                            </Col> 
                            <Col>
                            <div className="YouTubeShortContainer">
                                    <iframe
                                        className="responsive-iframe"
                                        src="https://youtube.com/embed/qeSXVmRdbe4?feature=share"
                                        title="YouTube video player"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                </div>
                            </Col>
                        </Row>

                    </Container>

                </Tab>
            </Tabs>
        </Row> 
        </Container>
        </section>
        </>
    )
}

export default WhatWeWorkedOn;