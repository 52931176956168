import Trust_Baxate from "../assets/img/Trust_Baxate.png";
import Trust_JeremyAwakens from "../assets/img/Trust_JeremyAwakens.png";
import Trust_DMAC from "../assets/img/Trust_DMAC.png";
import Trust_DCMMarketing from "../assets/img/Trust_DCMMarketing.png";
import Trust_PassionateFew from "../assets/img/Trust_ThePassionateFew.png";

import { Container, Row, Col } from "react-bootstrap";
export const Trust = () => {


  return (
    <section className="trust" id="trust"  style={{textAlign:"center", paddingBottom:"140px"}}>
        <div>
          <div style={{fontSize:"24px",lineHeight:"24px",textAlign:"center",fontWeight:"500",textTransform:"uppercase"}}>Companies, Brands & Creators that</div>
          <div  className="blueFadeText" style={{fontSize:"64px",lineHeight:"64px",textAlign:"center",fontWeight:"700", textTransform:"uppercase"}}>Trust Us</div>               
        </div>
        <Container>
          <Row>
           
            {/* <div style={{display:"flex", gap:"60px",justifyContent:"center",paddingTop:"20px"}}>
               */}
            <Col >
              <div className="profileBox">
                  <div className="banner_photo" >
                    <a  rel="noreferrer" href="https://www.youtube.com/channel/UCSihzA8lGVG6Tu3XVsKbnrQ" target="_blank"><img src={Trust_Baxate} alt="BAXATE" width="100%"/></a>
                  </div>
                  <div className="banner_profilename">Baxate</div>
                  {/* <div className="banner_title">Founder</div> */}
                </div>
                </Col>
                <Col >
                <div className="profileBox">
                  <div className="banner_photo" >
                  <a  rel="noreferrer" href="https://www.youtube.com/@JeremyAwakens" target="_blank"><img src={Trust_JeremyAwakens} alt="Jeremy Awakens" width="100%"/>                    </a>
                  </div>
                  <div className="banner_profilename">Jeremy Awakens</div>
                  {/* <div className="banner_title">Founder</div> */}
                </div>
                </Col>
                <Col >
                <div className="profileBox">
                  <div className="banner_photo" >
                  <a  rel="noreferrer" href="https://www.youtube.com/@DMac__" target="_blank"><img src={Trust_DMAC} alt="DMAC" width="100%"/></a>
                  </div>
                  <div className="banner_profilename">DMAC</div>
                  {/* <div className="banner_title">Founder</div> */}
                </div>
                </Col>
                <Col >
                <div className="profileBox">
                  <div className="banner_photo" >
                  <a  rel="noreferrer" href="https://www.dcm-marketing.com/" target="_blank"><img src={Trust_DCMMarketing} alt="DCM Marketing" width="100%"/></a>
                  </div>
                  <div className="banner_profilename">DCM Marketing</div>
                  {/* <div className="banner_title">Founder</div> */}
                </div>
                </Col>
                <Col >
                <div className="profileBox">
                  <div className="banner_photo" >
                  <a rel="noreferrer"  href="https://www.youtube.com/@thepassionatefew" target="_blank"> <img src={Trust_PassionateFew} alt="The Passionate Few" width="100%"/></a>

                  </div>
                  <div className="banner_profilename">ThePassionateFew</div>
                  {/* <div className="banner_title">Founder</div> */}
                </div>
                </Col>
              {/* </div> */}
          
          </Row>
          </Container>
    </section>

  )
}